import React from 'react';
import {Button} from '@material-ui/core';
import TransferCardForm from './TransferCardForm';
import WithTranslator from '../../providers/WithTranslator';
import FValidator from './FieldsValidator';

const defaultFields = {
    card: '',
    owner: '',
};

class TransferSendSecondStep extends React.Component {
    constructor(props) {
        super(props);

        const fields = this.props.fields ? {...this.props.fields} : {...defaultFields};
        this.state = {
            fields,
            errors: {}

        };
    }

    onSubmit = () => {
        const errors = {
            cardError: !FValidator.isCardValid(this.state.fields.card),
            ownerError: !FValidator.isOwnerValid(this.state.fields.owner),
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.props.onSubmit({...this.state.fields});
        }
    }

    render() {
        const translator = this.props.translator;
        return (
            <>
                <TransferCardForm storage={this} title={translator('transferPart.form.card2.title')}/>

                <div className='controls'>
                    <Button className='secondary _large' onClick={this.onSubmit}
                            disabled={!Object.values(this.state.fields).every(f => !!f) ||
                            Object.values(this.state.errors).some(e => !!e)
                            }>
                        {translator('transferPart.form.btn2.submit')}
                    </Button>
                    <Button className='secondary _large' onClick={this.props.onCancel}>
                        {translator('transferPart.form.btn2.cancel')}
                    </Button>
                </div>
            </>
        )
    }
}

export default WithTranslator(TransferSendSecondStep);
