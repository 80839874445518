export default {
    'header.link.main': 'Main',
    'header.link.transfer': 'Transfer',
    'header.link.commission': 'Commission',
    'header.link.questions': 'Questions',
    'header.link.contacts': 'Contacts',
    'header.lang.title': 'Lang',
    'header.btn.toTransferPart': 'Make transfer',

    'mainPart.title': 'Eldik Bank',
    'mainPart.description': 'Transfer money from card to card',
    'mainPart.btn.makeTransfer': 'Make transfer',
    'mainPart.btn.receiveTransfer': 'Receive transfer',

    'aboutPart.card1.title': 'Fast and convenient',
    'aboutPart.card1.text': 'rskp2p.com offers customers of all Russian banks a service for transferring money from card to card online in a fast and convenient way',
    'aboutPart.card2.title': 'Safely',
    'aboutPart.card2.text': `rskp2p.com is a secure environment, operates in accordance with the rules of the 'Visa' and 'MasterCard' systems, in compliance with the security standards of the payment card industry`,
    'aboutPart.card3.title': 'Protected',
    'aboutPart.card3.text': 'Payment transactions in our service are protected by SecureCode technology from Mastercard and 3D-Secure from Visa. The entered data is encrypted in accordance with the Protective Security Detail 2.0 and General Data Protection Regulation protocols',

    'transferPart.title': 'Transfer',
    'transferPart.menu.btn.makeTransfer': 'Send transfer',
    'transferPart.menu.btn.receiveTransfer': 'Receive transfer',
    'transferPart.form.amount.title': 'Transfer amount',
    'transferPart.form.amount.placeholder': 'Transfer amount',
    'transferPart.form.card1.title': 'Sender card',
    'transferPart.form.card2.title': 'Recipient\'s card',
    'transferPart.form.card.placeholder': 'Card number',
    'transferPart.form.owner.placeholder': 'Card owner',
    'transferPart.form.month.placeholder': 'Month',
    'transferPart.form.year.placeholder': 'Year',
    'transferPart.form.cvv.placeholder': 'CVV',
    'transferPart.form.commission1': 'Commission will be ',
    'transferPart.form.commission2': ' rubles',
    'transferPart.form.agree1': 'I agree with the conditions',
    'transferPart.form.agree2': 'offers',
    'transferPart.form.code': 'Get transfer code',
    'transferPart.form.code.tooltip': 'If you do not know the recipient\'s card details, you can get the transfer code. After sending the code to the recipient, he will enter the card on which he will receive money on his own',
    'transferPart.form.btn1.submit': 'Proceed',
    'transferPart.form.btn1.cancel': 'Reset',
    'transferPart.form.btn2.submit': 'Transfer',
    'transferPart.form.btn2.cancel': 'Back',
    'transferPart.form.get.submit': 'Get',
    'transferPart.warning': 'The term for crediting funds to the recipient\'s card account depends on the bank that issued the recipient\'s card and can range from several minutes to several days',
    'transferPart.result.wait.title': 'Transaction verification',
    'transferPart.result.wait.description': 'It usually takes a few seconds, do not close or refresh this page',
    'transferPart.result.code.title': 'Transfer code',
    'transferPart.result.code.description': 'Copy the code and send it to the recipient',
    'transferPart.result.code.submit': 'Submit a new transfer',
    'transferPart.result.success.title': 'Transfer completed successfully',
    'transferPart.result.success.description': 'The transfer was successful. Number of transfer is ',
    'transferPart.result.success.submit': 'Submit a new transfer',
    'transferPart.result.warning.title': 'Transfer error',
    'transferPart.result.warning.description': 'Check the correctness of the entered data <br/>We were unable to complete the transaction',
    'transferPart.result.warning.submit': 'Try again',

    'commissionPart.title': 'Commission',
    'commissionPart.card1.title': '1.5% of the amount, minimum 50 rubles',
    'commissionPart.card1.text': 'For internal transfers between cards of Russian banks',
    'commissionPart.card2.title': '2% of the amount, at least 200 rubles',
    'commissionPart.card2.text': 'For cross-border transfers from the RF card to foreign',

    'questionsPart.title': 'Questions',
    'questionsPart.card1.title': 'What cards can I use?',
    'questionsPart.card1.text': 'You can use Visa, MasterCard, Maestro and Mir cards for transfer. The sender\'s card must support "3D-Secure" / "World Accept" security technology. When transferring from a credit card, the bank that issued the card may charge an additional fee.<br/>' +
        'Some banks may not allow credit card transfers.',
    'questionsPart.card2.title': 'Are there any restrictions on the amount of transfers within Russia?',
    'questionsPart.card2.text': `Yes. The minimum amount of one transfer is 50 rubles, excluding commission<br/>The maximum amount of one internal transfer is 75,000 rubles, including commission<br/>The maximum amount of all internal transfers made during a calendar month using one card of the Sender of transfers is 600,000 (six hundred thousand) rubles of the Russian Federation.`,
    'questionsPart.card3.title': 'What are the restrictions on the amount of transfer to other countries?',
    'questionsPart.card3.text': 'The maximum amount of one cross-border transfer is USD 2,500 or the equivalent in the transfer currency, including commission.<br/> The maximum amount of all cross-border transfers made within a day of the month using one card of the Sender of transfers is 50,000 US dollars or the equivalent in Russian rubles at the Central Bank rate.<br/> The maximum amount of all cross-border transfers made within a day using one card of the Sender of transfers is 5,000 US dollars or the equivalent in Russian rubles at the exchange rate of the Central Bank.',

    'contactsPart.title': 'Contacts',
    'contactsPart.text1': 'Open Joint Stock Company',
    'contactsPart.text2': 'ELDIK BANK',
    'contactsPart.text3': '80/1 Moskovskaya st., Bishkek',
    'contactsPart.text4': 'E-mail: service@rskp2p.com',
    'contactsPart.form.title': 'Technical support',
    'contactsPart.form.email': 'Email (you will receive a reply)',
    'contactsPart.form.name': 'How can we call you?',
    'contactsPart.form.transaction': 'Number of transfer',
    'contactsPart.form.message': 'Your message',
    'contactsPart.form.btn.submit': 'Send message',
    'contactsPart.success.title': 'Review sent',
    'contactsPart.success.description': ', you will receive a reply by mail ',
    'contactsPart.warning.title': 'Something went wrong',
    'contactsPart.email.subject': trId => `Feedback (${trId})`,
    'contactsPart.email.message': (name, email, trId, message) => `Username:\n${name}\n\nUser mail:\n${email}\n\nTransaction:\n${trId}\n\nMessage:\n${message}`,
}
