import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Button} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import {RESULT_STATUS as STATUS} from '../../constants';

import {ReactComponent as Ellipse} from '../../resources/ellipse.svg';
import {ReactComponent as Success} from '../../resources/success.svg';
import {ReactComponent as Warning} from '../../resources/warning.svg';

class TransferResultStep extends React.Component {
    render() {
        const {translator, status} = this.props;
        return (
            <>
                <div className={`finish-step margin-bottom _${status}`} id='transferResult'>
                    <span className='h3'>{translator(`transferPart.result.${status}.title`)}</span>
                    {status === STATUS.WAIT
                        ?
                        <div className='progress'>
                            <Ellipse/>
                        </div>
                        : status === STATUS.SUCCESS
                            ?
                            <Success/>
                            : status === STATUS.ERROR
                                ? <Warning/>
                                : <TextInput value={this.props.trId} disabled withCopy/>

                    }
                    <span className='description'>
                        {ReactHtmlParser(translator(`transferPart.result.${status}.description`))}
                        {status === STATUS.SUCCESS && this.props.trId}
                    </span>
                </div>
                {status !== STATUS.WAIT &&
                <Button className='primary _large' onClick={this.props.onSubmit}>
                    {translator(`transferPart.result.${status}.submit`)}
                </Button>
                }
            </>
        )
    }
}

export default WithTranslator(TransferResultStep);
