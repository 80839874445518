import React from 'react';
import {Fab, Drawer} from '@material-ui/core';
import LanguageControl from './LanguageControl';
import WithTranslator from '../../providers/WithTranslator';

import './Header.scss';
import cancelIcon from '../../resources/icons/icon-cancel.svg';
import logo_en from '../../resources/logo_en.png';
import logo_ru from '../../resources/logo_ru.png';

class HeaderMobileMenu extends React.Component {
    goTo = element => {
        this.props.onClose();
        setTimeout(() => this.props.goTo(element));
    }

    render() {
        const {translator, open, onClose} = this.props;
        const currentLanguage = localStorage.getItem('language');

        return (
            <Drawer anchor='left' open={open} onClose={onClose} className='header-mobile-menu'>
                <Fab onClick={onClose} className='close-btn'>
                    <img src={cancelIcon} alt='close'/>
                </Fab>
                <img
                    src={currentLanguage === 'en' ? logo_en : logo_ru}
                    alt='Eldik'
                    className='logo'
                    style={{paddingTop: "16px"}}
                />
                <div className='menu'>
                    <a className={this.props.mainPartVisible ? '_active' : ''}
                       onClick={() => this.goTo(this.props.mainPart)}>
                        {translator('header.link.main')}
                    </a>
                    <a className={this.props.transferPartVisible ? '_active' : ''}
                       onClick={() => this.goTo(this.props.transferPart)}>
                        {translator('header.link.transfer')}
                    </a>
                    <a className={this.props.commissionPartVisible ? '_active' : ''}
                       onClick={() => this.goTo(this.props.commissionPart)}>
                        {translator('header.link.commission')}
                    </a>
                    <a className={this.props.questionsPartVisible ? '_active' : ''}
                       onClick={() => this.goTo(this.props.questionsPart)}>
                        {translator('header.link.questions')}
                    </a>
                    <a className={this.props.contactsPartVisible ? '_active' : ''}
                       onClick={() => this.goTo(this.props.contactsPart)}>
                        {translator('header.link.contacts')}
                    </a>
                </div>

                <LanguageControl mobile={true}/>
            </Drawer>
        )
    }
}

export default WithTranslator(HeaderMobileMenu);
