import React from 'react';
import {Button, Paper} from '@material-ui/core';
import TransferCardForm from './TransferCardForm';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';
import FValidator from './FieldsValidator';
import TransferResultStep from './TransferResultStep';
import apiService from '../../services/apiService';
import {RESULT_STATUS as STATUS} from '../../constants';

const defaultFields = {
    code: '',
    card: '',
    owner: '',
};

class TransferGet extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            status: null,
            fields: defaultFields,
            errors: {},
        };
    }

    componentDidMount() {
        this.part = document.getElementById('transferPart');
    }

    goUp = () => window.scrollTo({top: this.part.offsetTop - 88, behavior: 'smooth'});

    onSubmit = () => {
        this.goUp();
        const errors = {
            code: !this.state.fields.code,
            cardError: !FValidator.isCardValid(this.state.fields.card),
            ownerError: !FValidator.isOwnerValid(this.state.fields.owner),
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.send();
        }
    }

    send = () => {
        this.setState({status: STATUS.WAIT});
        const data = {
            'to': {
                'cardNumber': this.state.fields.card.replaceAll(' ', ''),
                'cardHolder': this.state.fields.owner,
            },
            'trId': this.state.fields.code
        }
        apiService.continueTransfer(data).then(state => {
            state.status === STATUS.CODE && (state.status = STATUS.ERROR);
            this.setState({...state})
        });
    }

    onStart = () => {
        this.goUp();
        const state = this.state.status === STATUS.ERROR
            ? {status: null}
            : {status: null, fields: {...defaultFields}};
        this.setState(state);
        window.history.pushState({}, null, '/');
    }

    render() {
        const translator = this.props.translator;
        return (
            <>
                {!this.state.status
                    ?
                    <>
                        <div className='margin-bottom amount'>
                            <span className='h3 margin-bottom'>{translator('transferPart.result.code.title')}</span>

                            <TextInput label={translator('transferPart.result.code.title')}
                                       value={this.state.fields.code}
                                       error={this.state.errors.codeError}
                                       onChange={this.FHelper.onCodeChange}/>
                        </div>

                        <TransferCardForm storage={this} title={translator('transferPart.form.card2.title')} />

                        <div className='controls'>
                            <Button className='secondary _large' onClick={this.onSubmit}
                                    disabled={
                                        !Object.entries(this.state.fields).every(([k, v]) => !!v)
                                        || Object.values(this.state.errors).some(e => !!e)
                                    }>
                                {translator('transferPart.form.get.submit')}
                            </Button>
                        </div>
                    </>
                    :
                    <TransferResultStep status={this.state.status} onSubmit={this.onStart}
                                        trId={this.state.trId}/>
                }
            </>
        )
    }
}

export default WithTranslator(TransferGet);
