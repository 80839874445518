import React from 'react';
import {AppBar, Button} from '@material-ui/core';
import LanguageControl from './LanguageControl';
import WithTranslator from '../../providers/WithTranslator';

import './Header.scss';
import logo_en from "../../resources/logo_en.png";
import logo_ru from "../../resources/logo_ru.png";

class HeaderAppBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            langAnchor: null
        }
    }

    setLanguage = lang => {
        this.setState({langAnchor: null});
        this.props.setLanguage(lang);
    }

    render() {
        const {translator, goTo} = this.props;
        const currentLanguage = localStorage.getItem('language');

        return (
            <>
                <AppBar className='appbar' id='header' position='relative'>
                    <img
                        src={currentLanguage === 'en' ? logo_en : logo_ru}
                        alt='Eldik'
                        width="159"
                        height="55"
                        className='logo'
                    />

                    <div className='menu'>
                        <a className={this.props.mainPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.mainPart)}>
                            {translator('header.link.main')}
                        </a>
                        <a className={this.props.transferPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.transferPart)}>
                            {translator('header.link.transfer')}
                        </a>
                        <a className={this.props.commissionPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.commissionPart)}>
                            {translator('header.link.commission')}
                        </a>
                        <a className={this.props.questionsPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.questionsPart)}>
                            {translator('header.link.questions')}
                        </a>
                        <a className={this.props.contactsPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.contactsPart)}>
                            {translator('header.link.contacts')}
                        </a>
                    </div>

                    <LanguageControl/>

                    <Button className='_large primary' onClick={() => goTo(this.props.transferPart)}>
                        {translator('header.btn.toTransferPart')}
                    </Button>
                </AppBar>
            </>
        )
    }
}

export default WithTranslator(HeaderAppBar);
