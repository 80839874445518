export default {
    'header.link.main': 'Главная',
    'header.link.transfer': 'Перевод',
    'header.link.commission': 'Комиссия',
    'header.link.questions': 'Вопросы',
    'header.link.contacts': 'Контакты',
    'header.lang.title': 'Язык',
    'header.btn.toTransferPart': 'Отправить перевод',

    'mainPart.title': 'Элдик Банк',
    'mainPart.description': 'Переводите деньги с карты на карту',
    'mainPart.btn.makeTransfer': 'Отправить перевод',
    'mainPart.btn.receiveTransfer': 'Получить перевод',

    'aboutPart.card1.title': 'Быстро и удобно',
    'aboutPart.card1.text': 'rskp2p.com предлагает клиентам всех российских банков сервис перевода денег с карты на карту онлайн быстрым и удобным способом',
    'aboutPart.card2.title': 'Безопасно',
    'aboutPart.card2.text': `rskp2p.com является безопасной средой, работает в соответствии с правилами  систем 'Visa' и 'MasterCard', с соблюдением требований стандартов безопасности  индустрии платежных карт`,
    'aboutPart.card3.title': 'Защищено',
    'aboutPart.card3.text': 'Платежные операции в нашем сервисе защищены технологией SecureCode от Mastercard и 3D-Secure от Visa. Введенные данные  шифруются в соответствии с  протоколами Protective Security Detail 2.0 и General Data Protection Regulation',

    'transferPart.title': 'Перевод',
    'transferPart.menu.btn.makeTransfer': 'Отправить перевод',
    'transferPart.menu.btn.receiveTransfer': 'Получить перевод',
    'transferPart.form.amount.title': 'Сумма перевода',
    'transferPart.form.amount.placeholder': 'Сумма перевода',
    'transferPart.form.card1.title': 'Карта отправителя',
    'transferPart.form.card2.title': 'Карта получателя',
    'transferPart.form.card.placeholder': 'Номер карты',
    'transferPart.form.owner.placeholder': 'Держатель карты',
    'transferPart.form.month.placeholder': 'Месяц',
    'transferPart.form.year.placeholder': 'Год',
    'transferPart.form.cvv.placeholder': 'CVV',
    'transferPart.form.commission1': 'Комиссия составит ',
    'transferPart.form.commission2': ' рублей',
    'transferPart.form.agree1': 'Я согласен с условиями',
    'transferPart.form.agree2': 'оферты',
    'transferPart.form.code': 'Получить код перевода',
    'transferPart.form.code.tooltip': 'Если вы не знаете данные карты получателя, вы можете получить код перевода. После отправления кода получателю, он введет карту, на которую получит деньги самостоятельно',
    'transferPart.form.btn1.submit': 'Продолжить',
    'transferPart.form.btn1.cancel': 'Сбросить',
    'transferPart.form.btn2.submit': 'Перевести',
    'transferPart.form.btn2.cancel': 'Назад',
    'transferPart.form.get.submit': 'Получить',
    'transferPart.warning': 'Срок зачисления средств на счет карты получателя зависит от банка, выпустившего карту получателя, и может составлять от нескольких минут до нескольких дней',
    'transferPart.result.wait.title': 'Проверка транзакции',
    'transferPart.result.wait.description': 'Обычно это занимает несколько секунд, не закрывайте и не обновляйте эту страницу',
    'transferPart.result.code.title': 'Код перевода',
    'transferPart.result.code.description': 'Скопируйте код и отправьте его получателю',
    'transferPart.result.code.submit': 'Отправить новый перевод',
    'transferPart.result.success.title': 'Перевод завершён успешно',
    'transferPart.result.success.description': 'Транзакция прошла успешно. Номер перевода ',
    'transferPart.result.success.submit': 'Отправить новый перевод',
    'transferPart.result.warning.title': 'Ошибка перевода',
    'transferPart.result.warning.description': 'Проверьте правильность введенных данных<br/> Нам не удалось провести транзакцию',
    'transferPart.result.warning.submit': 'Попробовать еще раз',

    'commissionPart.title': 'Комиссия',
    'commissionPart.card1.title': '1,5% от суммы, минимум 50₽',
    'commissionPart.card1.text': 'На внутренние переводы между картами Российских банков',
    'commissionPart.card2.title': '2% от суммы, минимум 200₽',
    'commissionPart.card2.text': 'На трансграничные переводы с карты РФ на иностранные',

    'questionsPart.title': 'Вопросы',
    'questionsPart.card1.title': 'Какие карты можно использовать?',
    'questionsPart.card1.text': `Для перевода можно использовать карты Visa, MasterCard, Maestro и Мир. Карта отправителя должна поддерживать технологию безопасности «3D-Secure»/«Мир Accept» При переводе с кредитной карты банк, выпустивший карту, может взимать дополнительную комиссию.<br/>В некоторых банках переводы с кредитных карт могут быть запрещены.`,
    'questionsPart.card2.title': 'Есть ли ограничения по сумме перевода внутри России?',
    'questionsPart.card2.text': `Да. Минимальная сумма одного перевода — 50 рублей, без учета комиссии<br/>Максимальная сумма одного внутреннего перевода — 75 000 рублей, с учетом комиссии<br/>Максимальная сумма всех внутренних переводов, осуществленных в течение календарного месяца с использованием одной карты Отправителя переводов — 600 000 (шестьсот тысяч) рублей Российской Федерации.`,
    'questionsPart.card3.title': 'Какие ограничения по сумме перевода в другие страны?',
    'questionsPart.card3.text': 'Максимальная сумма одного трансграничного перевода — 2 500 долларов США или эквивалент в валюте перевода, включая комиссию.<br/>\rМаксимальная сумма всех трансграничных переводов, осуществленных в течение суток месяца с использованием одной карты Отправителя переводов - 50 000 долларов США или эквивалент в рублях РФ по курсу ЦБ.<br/>Максимальная сумма всех трансграничных переводов, осуществленных в течение суток с использованием одной карты Отправителя переводов - 5 000 долларов США или эквивалент в рублях РФ по курсу ЦБ.',

    'contactsPart.title': 'Контакты',
    'contactsPart.text1': 'Открытое Акционерное Общество',
    'contactsPart.text2': '«Элдик Банк»',
    'contactsPart.text3': 'г. Бишкек, ул. Московская, 80/1',
    'contactsPart.text4': 'E-mail: service@rskp2p.com',
    'contactsPart.form.title': 'Техническая поддержка',
    'contactsPart.form.email': 'Email (на него придет ответ)',
    'contactsPart.form.name': 'Как к вам обращаться?',
    'contactsPart.form.transaction': 'Номер перевода',
    'contactsPart.form.message': 'Ваше сообщение',
    'contactsPart.form.btn.submit': 'Отправить',
    'contactsPart.success.title': 'Отзыв отправлен',
    'contactsPart.success.description': ', вы получите ответ на почту ',
    'contactsPart.warning.title': 'Что-то пошло не так',
    'contactsPart.email.subject': trId => `Обратная связь (${trId})`,
    'contactsPart.email.message': (name, email, trId, message) => `Имя пользователя:\n${name}\n\nПочта пользователя:\n${email}\n\nТранзакиця:\n${trId}\n\nСообщение:\n${message}`,
}
