import React from 'react';
import {Paper} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';

import './AboutPart.scss';

import iconTime from '../../resources/icons/icon-time.svg';
import iconStar from '../../resources/icons/icon-star.svg';
import iconSeq from '../../resources/icons/icon-seq.svg';
import iconTimeSmall from '../../resources/icons/icon-time-small.svg';
import iconStarSmall from '../../resources/icons/icon-star-small.svg';
import iconSeqSmall from '../../resources/icons/icon-seq-small.svg';

class AboutPart extends React.Component {
    constructor(props) {
        super(props);
        this.cardsRef = React.createRef();

        this.state = {
            height: 0
        }
    }

    componentDidMount() {
        this.updateHeight();
        this.cardsRef.current.style['visibility'] = 'visible';
        window.addEventListener('resize', this.updateHeight, {passive: true});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeight);
    }

    updateHeight = () => {
        const height = document.documentElement.clientWidth > 1200
            ? this.cardsRef.current.clientHeight / 2
            : this.cardsRef.current.clientHeight - 60;
        this.setState({height});
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='about-part'>
                <div className='cards-container' ref={this.cardsRef}>
                    {[
                        {
                            icon: iconTime,
                            iconSmall: iconTimeSmall,
                            title: translator('aboutPart.card1.title'),
                            text: translator('aboutPart.card1.text')
                        },
                        {
                            icon: iconStar,
                            iconSmall: iconStarSmall,
                            title: translator('aboutPart.card2.title'),
                            text: translator('aboutPart.card2.text')
                        },
                        {
                            icon: iconSeq,
                            iconSmall: iconSeqSmall,
                            title: translator('aboutPart.card3.title'),
                            text: translator('aboutPart.card3.text')
                        },
                    ].map((item, i) =>
                        <Paper className='card' key={i}>
                            <div className='full-mode'>
                                <img src={item.icon} alt='time'/>
                                <div className='cardBody'>
                                    <span className='h3 title'>
                                        {item.title}
                                    </span>
                                    {item.text}
                                </div>
                            </div>
                            <div className='small-mode'>
                                <img src={item.iconSmall} alt="time"/>
                                <div className="cardBody">
                                    <span className="h3 title">
                                        {item.title}
                                    </span>
                                    {item.text}
                                </div>
                            </div>
                        </Paper>
                    )}
                </div>
            </div>
        )
    }
}

export default WithTranslator(AboutPart);